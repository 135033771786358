import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { matchEventId } from "developments/developmentSlice";
import { removeMatch } from "developments/developmentSlice";
import { matchEventTime } from "developments/developmentSlice";
import { matchShirtColor } from "developments/developmentSlice";
import { matchShirtNumber } from "developments/developmentSlice";
import { matchShareLink } from "developments/developmentSlice";
import { matchWipLink } from "developments/developmentSlice";
import { matchLearnLink } from "developments/developmentSlice";
import { matchAdvice } from "developments/developmentSlice";
import { matchPosition } from "developments/developmentSlice";

class MatchInfo extends React.Component {
  
  handleRemoveMatch = event => {
    event.preventDefault();
    const { id } = this.props;
    this.props.dispatch(removeMatch(id));
  }

  handleChangeEvent = event => {
    const { id } = this.props;
    this.props.dispatch(matchEventId(id, event.label));
    this.props.dispatch(matchEventTime(id, event.value));
  }

  handleChangeShirtColor = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchShirtColor(id, e.target.value));
  }

  handleChangeShirtNumber = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchShirtNumber(id, e.target.value));
  }

  handleChangeShareLink = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchShareLink(id, e.target.value));
  }

  handleChangeWipLink = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchWipLink(id, e.target.value));
  }

  handleChangeLearnLink = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchLearnLink(id, e.target.value));
  }

  handleChangeAdvice = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchAdvice(id, e.target.value));
  }

  handleChangePosition = e => {
    e.preventDefault();
    const { id } = this.props;
    this.props.dispatch(matchPosition(id, e.target.value));
  }

  render() {
    const { match, eventList } = this.props;
    const { eventId, eventTime, shirtColor, shirtNumber, shareLink, wipLink, learnLink, advice, position } = match;
    const eventSelected = {label: eventId, value: eventTime};
    const eventOptions = eventList ? eventList.map(event => { return {label: event.id, value: event.eventTime} }) : [];
    return (
      <>
      <div>
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Match Information
        </h6>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Event
              </label>
              <div className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <Select options={eventOptions} value={eventSelected} required onChange={this.handleChangeEvent} />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Color
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtColor"
                name="shirtColor" 
                value={shirtColor} onChange={this.handleChangeShirtColor} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Shirt Number
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShirtNumber"
                name="shirtNumber" 
                value={shirtNumber} onChange={this.handleChangeShirtNumber} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Share Link
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ShareLink"
                name="shareLink" 
                value={shareLink} onChange={this.handleChangeShareLink} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                WIP Link
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="WipLink"
                name="wipLink" 
                value={wipLink} onChange={this.handleChangeWipLink} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Learn Link
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="LearnLink"
                name="learnLink" 
                value={learnLink} onChange={this.handleChangeLearnLink} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Advice
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Advice"
                name="advice" 
                value={advice} onChange={this.handleChangeAdvice} 
              />
            </div>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Position
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Position"
                name="position" 
                value={position} onChange={this.handleChangePosition} 
              />
            </div>
          </div>
          <div className="px-4 py-4 md:px-10 mx-auto w-full text-center">
            <button
                className="font-normal text-lightBlue-500 px-6"
                onClick={this.handleRemoveMatch}
            >
              <i className="fas fa-trash-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
              remove match
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  eventList: state.events.eventList,
  error: state.developments.error
});

export default connect(mapStateToProps)(MatchInfo);
