import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import { stringify } from 'qs'
import Cookies from 'universal-cookie';
import { formatDate, getLocalMonth } from '../utils/utils';

const nanoid = customAlphabet('1234567890', 8)

export const listDevelopments = createAsyncThunk("developments/listDevelopments", async (development, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/list_developments", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        startTime: development.startTime,
        endTime: development.endTime
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const showDevelopments = createAsyncThunk("developments/showDevelopments", async (development, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const listAxios = axios.create({
      paramsSerializer: {
        serialize: stringify,
        indices: false
      }
    });
    const res = await listAxios.get("/api/show_developments", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        time: development.time,
        playerId: development.playerId
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getDevelopment = createAsyncThunk("developments/getDevelopment", async (development, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.get("/api/get_development", {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      params: {
        time: formatDate(new Date(development.timeKey)),
        playerId: development.playerId
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateDevelopment = createAsyncThunk("developments/updateDevelopment", async (development, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.post("/api/update_development", development, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeDevelopment = createAsyncThunk("developments/removeDevelopment", async (development, { rejectWithValue }) => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    const res = await axios.delete("/api/remove_development/" + formatDate(new Date(development.month))+ "/" 
      + development.playerId, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    if (res.status !== 200) {
      return rejectWithValue("Wrong status " + res.status);
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const initialState = {
  id: '',
  playerId: '',
  playerName: '',
  contactEmail: '',
  birthYear: '',
  month: 0,
  teamId: '',
  matches: [],
  developments: [],
  developmentList: [],
  currentPlayer: '',
  currentMonth: getLocalMonth(new Date()),
  currentMatch: 0,
  curMatches: [],
  matchTotal: 0,
  loading: false,
  uError: null,
  error: null
}

const developmentSlice = createSlice({
  name: 'developments',
  initialState,
  reducers: {
    developmentAdded: {
      reducer(state, action) {
        state.developmentList.push(action.payload);
      },
      prepare(month, contactEmail, playerId) {
        return {
          payload: {
            id: nanoid(),
            playerId: playerId,
            month: month,
            playerName: '',
            contactEmail: contactEmail,
            birthYear: 0,
            teamId: '',
            matches: []
          }
        }
      }
    },
    changeId(state, action) {
      state.id = action.payload;
      state.error = null;
      state.uError = null;
    },
    changePlayerId(state, action) {
      state.playerId = action.payload;
      state.error = null;
      state.uError = null;
    },
    changePlayerName(state, action) {
      state.playerName = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeContactEmail(state, action) {
      state.contactEmail = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeBirthYear(state, action) {
      state.birthYear = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeTeamId(state, action) {
      state.teamId = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeMonth(state, action) {
      state.month = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeMatches(state, action) {
      state.matches = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeMatches(state, action) {
      state.matches = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeCurrentPlayer(state, action) {
      state.currentPlayer = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeCurrentMonth(state, action) {
      state.currentMonth = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeCurrentMatch(state, action) {
      state.currentMatch = action.payload;
      state.error = null;
      state.uError = null;
    },
    changeMatchTotal(state, action) {
      state.matchTotal = action.payload;
      state.error = null;
      state.uError = null;
    },
    matchAdded: {
      reducer(state, action) {
        if (!state.matches) {
          state.matches = [action.payload];
        } else {
          state.matches.push(action.payload);
        }
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
            eventId: '',
            eventTime: 0,
            shirtColor: '',
            shirtNumber: 0,
            shareLink: '',
            wipLink: ''
          }
        }
      }
    },
    matchEventId: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                eventId: action.payload.eventId
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, eventId) {
        return {
          payload: {
            id: id,
            eventId: eventId
          }
        }
      }
    },
    matchEventTime: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                eventTime: action.payload.eventTime
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, eventTime) {
        return {
          payload: {
            id: id,
            eventTime: eventTime
          }
        }
      }
    },
    matchShirtColor: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                shirtColor: action.payload.shirtColor
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, shirtColor) {
        return {
          payload: {
            id: id,
            shirtColor: shirtColor
          }
        }
      }
    },
    matchShirtNumber: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                shirtNumber: action.payload.shirtNumber
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, shirtNumber) {
        return {
          payload: {
            id: id,
            shirtNumber: shirtNumber
          }
        }
      }
    },
    matchShareLink: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                shareLink: action.payload.shareLink
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, shareLink) {
        return {
          payload: {
            id: id,
            shareLink: shareLink
          }
        }
      }
    },
    matchWipLink: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                wipLink: action.payload.wipLink
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, wipLink) {
        return {
          payload: {
            id: id,
            wipLink: wipLink
          }
        }
      }
    },
    matchLearnLink: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                learnLink: action.payload.learnLink
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, learnLink) {
        return {
          payload: {
            id: id,
            learnLink: learnLink
          }
        }
      }
    },
    matchAdvice: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                advice: action.payload.advice
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, advice) {
        return {
          payload: {
            id: id,
            advice: advice
          }
        }
      }
    },
    matchPosition: {
      reducer(state, action) {
        return {
          ...state,
          matches: state.matches.map(match => {
            if (match.id === action.payload.id) {
              return { 
                ...match, 
                position: action.payload.position
              };
            } else {
              return match;
            }
          })
        };
      },
      prepare(id, position) {
        return {
          payload: {
            id: id,
            position: position
          }
        }
      }
    },
    removeMatch(state, action) {
      state.matches = state.matches.filter((current) => current.id !== action.payload);
    }
  },
  extraReducers: {
    [listDevelopments.pending]: (state, action) => {
      state.loading = true;
    },
    [listDevelopments.fulfilled]: (state, action) => {
      state.loading = false;
      state.developmentList = action.payload;
    },
    [listDevelopments.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [showDevelopments.pending]: (state, action) => {
      state.curMatches = [];
      state.loading = true;
    },
    [showDevelopments.fulfilled]: (state, action) => {
      state.loading = false;
      state.developments = action.payload;
      for (let i=0; i<action.payload.length; i++) {
        if (!state.curMatches) {
          state.curMatches = action.payload[i].matches;
        } else {
          state.curMatches.push(...action.payload[i].matches);
        }
      }
    },
    [showDevelopments.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getDevelopment.pending]: (state, action) => {
      state.loading = true;
    },
    [getDevelopment.fulfilled]: (state, action) => {
      state.loading = false;
      state.developmentList = state.developmentList.map(development => {
        if (development.playerId === action.payload.playerId) {
          return { 
            ...development, 
            matches: action.payload.matches
          };
        } else {
          return development;
        }
      })
      state.error = null;
    },
    [getDevelopment.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateDevelopment.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDevelopment.fulfilled]: (state, action) => {
      state.loading = false;
      state.developmentList = state.developmentList.map(development => {
        if (development.id === action.payload.id) {
          return {
            ...development,
            playerId: action.payload.playerId,
            playerName: state.playerName,
            contactEmail: state.contactEmail,
            birthYear: state.birthYear,
            month: state.month,
            teamId: state.teamId,
            matches: state.matches
          }
        } else {
          return development;
        }
      });
      state.error = null;
      state.uError = null;
    },
    [updateDevelopment.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [removeDevelopment.pending]: (state, action) => {
      state.loading = true;
    },
    [removeDevelopment.fulfilled]: (state, action) => {
      state.loading = false;
      state.developmentList = state.developmentList.filter((current) => current.playerId !== action.payload.toString() );
      state.error = null;
      state.uError = null;
    },
    [removeDevelopment.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
})

export const { developmentAdded, changeId, changePlayerId, changePlayerName, changeContactEmail, changeBirthYear, changeMonth, changeTeamId, 
  changeMatches, matchAdded, matchEventId, matchEventTime, matchShirtColor, matchShirtNumber, matchShareLink, matchWipLink, matchLearnLink, 
  matchAdvice, matchPosition, removeMatch, changeCurrentPlayer, changeCurrentMonth, changeCurrentMatch, changeMatchTotal } = developmentSlice.actions
export default developmentSlice.reducer