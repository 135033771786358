import React from "react";
import { connect } from "react-redux";

import DevSide from "components/Sidebar/DevSide.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import CardPreview from "components/Cards/CardPreview";
import Cookies from 'universal-cookie';
import { getCurrentUser } from "users/userSlice";
import { showDevelopments } from "developments/developmentSlice";
import { changeCurrentMatch } from "developments/developmentSlice";

class Players extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const cookies = new Cookies();
    const token = cookies.get('fa-access-token');
    if ((!currentUser || !currentUser.roles) && token) {
      this.props.dispatch(getCurrentUser(token));
    }
  }

  componentDidMount() {
    const { currentUser, currentPlayer, currentMonth } = this.props;
    if (currentUser && currentPlayer && currentMonth) {
      this.props.dispatch(showDevelopments({time: currentMonth, playerId: currentPlayer}));
      this.props.dispatch(changeCurrentMatch(0));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, currentPlayer, currentMonth } = this.props;
    if (currentUser && currentPlayer && currentMonth && (currentPlayer !== prevProps.currentPlayer || 
      currentUser !== prevProps.currentUser || currentMonth !== prevProps.currentMonth )) {
      this.props.dispatch(showDevelopments({time: currentMonth, playerId: currentPlayer}));
      this.props.dispatch(changeCurrentMatch(0));
    }
  }

  render() {
    const { currentMatch, curMatches } = this.props;

    var shareLink, wipLink, learnLink, advice;
    if (curMatches && currentMatch < curMatches.length) {
      shareLink = curMatches[currentMatch].shareLink;
      wipLink = curMatches[currentMatch].wipLink;
      learnLink = curMatches[currentMatch].learnLink;
      advice = curMatches[currentMatch].advice;
    }

    return (
      <>
      <DevSide />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <IndexNavbar /> */}
        <div className="relative bg-lightBlue-100 :pt-32 pb-4 pt-16">
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
          {(shareLink && shareLink.endsWith("mov")) && "Share"}
          {(shareLink && shareLink.endsWith("png")) && "Touches"}
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            {(shareLink && shareLink.endsWith("mov")) && <CardPreview url={shareLink} />}
            {(shareLink && shareLink.endsWith("png")) && <img src={shareLink} width="853" height="600"/>}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">
            {" "}
            {(shareLink) && <button onClick={() => {navigator.clipboard.writeText(shareLink)}}>
            {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
            </button>}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
          {(wipLink && wipLink.endsWith("mov")) && "Develop"}
          {(wipLink && wipLink.endsWith("png")) && "Passing"}
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            {(wipLink && wipLink.endsWith("mov")) && <CardPreview url={wipLink} />}
            {(wipLink && wipLink.endsWith("png")) && <img src={wipLink} width="853" height="600" />}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">
            {" "}
            {(wipLink) && <button onClick={() => {navigator.clipboard.writeText(wipLink)}}>
            {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
            </button>}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-4 text-center">
          {(learnLink && learnLink.endsWith("mov")) && "Learn"}
          {(learnLink && learnLink.endsWith("png")) && "Finish"}
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            {(learnLink && learnLink.endsWith("mov")) && <CardPreview url={learnLink} />}
            {(learnLink && learnLink.endsWith("png")) && <img src={learnLink} width="853" height="600" />}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">
            {" "}
            {(learnLink) && <button onClick={() => {navigator.clipboard.writeText(learnLink)}}>
            {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
            </button>}
          </div>
          <div className="flex w-full xl:w-12/12 mb-4 xl:mb-0 px-4 justify-center">
            {(advice && advice.endsWith("mov")) && <CardPreview url={advice} />}
          </div>
          <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">
            {(advice && !advice.endsWith("mov")) && advice + " "}
            {(advice && advice.endsWith("mov")) && <button onClick={() => {navigator.clipboard.writeText(wipLink)}}>
            {" "}<i className="fas fa-copy text-white-400 mr-2 text-sm"></i>
            </button>}
          </div>
          {(!shareLink) && (!wipLink) && (!learnLink) && <div className="px-4 md:px-10 mx-auto w-full mt-4 mb-1 text-center">No Games, check other times</div>}
        </div>
        <FooterAdmin />
      </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  currentPlayer: state.developments.currentPlayer,
  currentMonth: state.developments.currentMonth,
  currentMatch: state.developments.currentMatch,
  curMatches: state.developments.curMatches,
  error: state.developments.error
});

export default connect(mapStateToProps)(Players);
